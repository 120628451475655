import React from 'react'
import Layout from '../components/Layout.js'
import { Center } from '@patomation/react-ui-components'


const Checkout = () => {

  return (
   <Layout>

      <Center style={{textAlign: 'center'}}>

        <h1>
          Thank You
        </h1>

        You booking is confirmed.
        You will recieve an email shortly.

      </Center>

   </Layout>
 )

}

export default Checkout
